// Additional country settings for sanity are added in 'countrySettingsWithLanguages.js'

export const countrySettings = {
  AT: {
    title: 'Austria',
    officeType: 'RetailOnly',
    flagIcon: 'AT'
  },
  BE: {
    title: 'Belgium',
    officeType: 'LHO',
    flagIcon: 'BE',
  },
  DK: {
    title: 'Denmark',
    officeType: 'LHO',
    flagIcon: 'DK',
  },
  FI: {
    title: 'Finland',
    officeType: 'LHO',
    flagIcon: 'FI',
  },
  FR: {
    title: 'France',
    officeType: 'LHO',
    flagIcon: 'FR',
  },
  DE: {
    title: 'Germany',
    officeType: 'LHO',
    flagIcon: 'DE',
  },
  IE: {
    title: 'Ireland',
    officeType: 'LHO',
    flagIcon: 'IE',
  },
  IT: {
    title: 'Italy',
    officeType: 'LHO',
    flagIcon: 'IT',
  },
  LU: {
    title: 'Luxembourg',
    officeType: 'RetailOnly',
    flagIcon: 'LU',
  },
  NL: {
    title: 'Netherlands',
    officeType: 'CHO',
    flagIcon: 'NL',
  },
  PL: {
    title: 'Poland',
    officeType: 'LHO',
    flagIcon: 'PL',
  },
  RO: {
    title: 'Romania',
    officeType: 'RetailOnly',
    flagIcon: 'RO',
  },
  NO: {
    title: 'Norway',
    officeType: 'LHO',
    flagIcon: 'NO',
  },
  PT: {
    title: 'Portugal',
    officeType: 'LHO',
    flagIcon: 'PT',
  },
  ES: {
    title: 'Spain',
    officeType: 'LHO',
    flagIcon: 'ES',
  },
  SE: {
    title: 'Sweden',
    officeType: 'LHO',
    flagIcon: 'SE',
  },
  CH: {
    title: 'Switzerland',
    officeType: 'RetailOnly',
    flagIcon: 'CH',
  },
  GB: {
    title: 'United Kingdom',
    officeType: 'LHO',
    flagIcon: 'GB',
  },
}
