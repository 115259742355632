import da_DK from './da_DK.po'
import de_DE from './de_DE.po'
import en_GB from './en_GB.po'
import es_ES from './es_ES.po'
import fi_FI from './fi_FI.po'
import fr_FR from './fr_FR.po'
import it_IT from './it_IT.po'
import nl_NL from './nl_NL.po'
import nb_NO from './nb_NO.po'
import pl_PL from './pl_PL.po'
import sv_SE from './sv_SE.po'
import pt_PT from './pt_PT.po'
import ro_RO from './ro_RO.po'

import { sprintf } from 'sprintf-js'

const translations = { nl_NL, en_GB, da_DK, de_DE, es_ES, fi_FI, it_IT, nb_NO, pl_PL, sv_SE, pt_PT, fr_FR, ro_RO }

export function translateSingular(language, id, args = {}, context = '') {
  return translatePlural(language, id, 1, args, context)
}

export function translatePlural(language, id, count, args = {}, context = '') {
  const isPlural = count !== 1 // This could be determined by parsing the plural-forms header
  const [singular, plural] = getTranslations(language, id, context)

  const msg = isPlural ? plural : singular
  try {
    if (msg === undefined) throw new Error('No translation found')

    return sprintf(msg, args)
  } catch (e) {
    throw new Error(`Failed to translate '${id}'${msg ? `('${msg}')` : ''} into '${language}' (count: ${count}, args: ${JSON.stringify(args)}): ${e}`)
  }
}

export function hasTranslation(language, id, context = '') {
  const [singular] = getTranslations(language, id, context)
  return singular !== undefined
}

function getTranslations(language, id, context) {
  return translations[language]?.translations[context]?.[id]?.msgstr ?? []
}
