import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export { dayjs, unixTimeStampToFormattedDate }

function unixTimeStampToFormattedDate(timestamp) {
  return timestamp && dayjs(timestamp).format('YYYY-MM-DD')
}
