/* When adding or removing a language also change this in:
  - config/@kaliber/sanity-plugin-multi-language.json
  - services/languageMapping.js
  - src/i18n/translations.js
  - src/default.js
  - admin/config/countrySettingsWithLanguages.js
*/

// TODO: https://kaliber.atlassian.net/browse/RITC-1113
export const languageMapping = {
  en_GB: {
    locales: ['en-NL', 'en-GB', 'en-IE'],
    title: 'English'
  },
  nl_NL: {
    locales: ['nl-BE', 'nl-NL' ],
    title: 'Dutch',
  },
  fr_FR: {
    locales: ['fr-BE', 'fr-LU', 'fr-FR', 'fr-CH'],
    title: 'French',
  },
  de_DE: {
    locales: ['de-DE', 'de-AT', 'de-CH'],
    title: 'German',
  },
  da_DK: {
    locales: ['da-DK' ],
    title: 'Danish',
  },
  nb_NO: {
    locales: ['nb-NO' ],
    title: 'Norwegian',
  },
  sv_SE: {
    locales: ['sv-SE' ],
    title: 'Swedish',
  },
  fi_FI: {
    locales: ['fi-FI' ],
    title: 'Finnish',
  },
  es_ES: {
    locales: ['es-ES' ],
    title: 'Spanish',
  },
  pt_PT: {
    locales: ['pt-PT' ],
    title: 'Portuguese',
  },
  pl_PL: {
    locales: ['pl-PL'],
    title: 'Polish',
  },
  it_IT: {
    locales: ['it-IT' ],
    title: 'Italian',
  },
  ro_RO: {
    locales: ['ro-RO' ],
    title: 'Romanian',
  },
}
