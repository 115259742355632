/** @typedef {{
      (x: TemplateStringsArray, ...args: Array<any>): string;
      (count: number): (x: TemplateStringsArray, ...args: Array<any>) => string;
      (count: number, args: object): (x: TemplateStringsArray, ...args: Array<any>) => string;
      (args: object): (x: TemplateStringsArray, ...args: Array<any>) => string;
    }} Translate */
/** @type {React.Context<{ language: string, translate: Translate, hasTranslation: (id: string, ...args: Array<any>) => boolean }>} */
const i18nContext = React.createContext({ language: null, translate: null, hasTranslation: null })

export function I18nProvider({ language, children, translateSingular, translatePlural, hasTranslation: originalHasTranslation }) {
  const translate = React.useCallback(
    (x, ...args) => {
      if (typeof x === 'number')
        return asInterpolationFunction(id => translatePlural(language, id, x, ...args))
      if (typeof x === 'object' && !Array.isArray(x))
        return asInterpolationFunction(id => translateSingular(language, id, x, ...args))
      else
        return translateSingular(language, interpolate(x, ...args))
    },
    [language, translatePlural, translateSingular]
  )

  const hasTranslation = React.useCallback(
    (id, ...args) => originalHasTranslation(language, id, ...args),
    [language, originalHasTranslation]
  )

  const value = React.useMemo(() => ({ translate, language, hasTranslation }), [translate, language, hasTranslation])

  return <i18nContext.Provider {...{ children, value }} />
}

export function useLanguage() {
  const { language } = React.useContext(i18nContext)
  return language
}

export function useTranslate() {
  const { translate, hasTranslation } = React.useContext(i18nContext)
  return { __: translate, hasTranslation }
}

function asInterpolationFunction(f) {
  return (segments, ...args) => f(interpolate(segments, ...args))
}

function interpolate(segments, ...args) {
  return segments.reduce(
    (result, segment, i) => i ? `${result}${args[i - 1]}${segment}` : segment,
    ''
  )
}
