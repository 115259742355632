import { languageMapping } from '/i18n/languageMapping'

export const installedLanguages = installedLanguageLookup()

function installedLanguageLookup() {
  return Object.fromEntries(
    Object.entries(languageMapping).flatMap(
      ([language, data]) => data.locales.map(locale => [locale, language])
    )
  )
}
